'use client';

import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { generateResume } from '@/libs/generateResume';
import PersonalCard from '@/components/PersonalCard';
import { useRouter } from 'next/navigation';
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import toast from "react-hot-toast";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { languages } from "@/navigation"

import { useTranslations } from 'next-intl';
import { ResumeContent, ResumeData } from '@/types/resume';

export interface ResumeFormData {
    name: string;
    location?: string;
    language?: string;
    email?: string;
    title?: string;
    slug: string;
    achievements?: string;
    skills?: string;
    recentWork?: string;
    tags?: string[];
    recentFocus?: string;
    highlights?: string[];
    expertise?: { name: string; description: string }[];
    interests?: string[];
    motto?: string;
    is_publish?: number;
    id?: number;
}


// const sampleResumeDataZh: ResumeContent = {
//     id: 1,
//     name: "孙悟空",
//     email: "support@ai-about.me",
//     location: "花果山",
//     tags: ["保镖", "和尚"],
//     recentFocus: "正在进行西天取经的伟大冒险",
//     highlights: [
//         "成功大闹天宫，展现非凡实力",
//         "担任唐僧的得力保镖，护送西行"
//     ],
//     expertise: [
//         { name: "变化之术", description: "精通72变，千变万化" },
//         { name: "武术格斗", description: "精通棍法，战斗力超群" },
//         { name: "筋斗云", description: "瞬间移动，一个筋斗十万八千里" },
//         { name: "妖怪克星", description: "专业打妖除魔，保护师父" }
//     ],
//     interests: ["打妖怪", "吃蟠桃", "交友广泛", "冒险探索"],
//     motto: "我是齐天大圣孙悟空",
//     slug: "sunwukong",
//     is_publish: 1,
// };

const sampleResumeDataEn: ResumeContent = {
    id: 1,
    name: "Sun Wukong",
    email: "support@ai-about.me",
    location: "Flower Fruit Mountain",
    tags: ["Bodyguard", "Monk"],
    recentFocus: "Currently on a great adventure to journey to the West",
    highlights: [
        "Successfully caused havoc in Heaven, demonstrating extraordinary abilities",
        "Serving as Tang Monk's capable bodyguard, escorting him on the westward journey"
    ],
    expertise: [
        { name: "Transformation Skills", description: "Mastered 72 transformations, capable of countless forms" },
        { name: "Martial Arts", description: "Expert in staff techniques, possessing superior combat skills" },
        { name: "Cloud Somersault", description: "Instant transportation, one somersault covers 108,000 miles" },
        { name: "Demon Buster", description: "Professional in defeating demons and protecting the master" }
    ],
    interests: ["Fighting demons", "Eating immortal peaches", "Extensive networking", "Adventure and exploration"],
    motto: "I am the Great Sage Equal to Heaven, Sun Wukong",
    slug: "sunwukong",
    is_publish: 1,
};


const ResumeForm: React.FC = () => {

    const t = useTranslations('ResumeForm');

    const router = useRouter();

    const [resumeContent, setResumeContent] = useState<ResumeContent>(sampleResumeDataEn);
    const [resumeData, setResumeData] = useState<ResumeData>();

    const formSchema = z.object({
        name: z.string().min(1, t('Enter your name')),
        location: z.string().optional(),
        language: z.string().optional(),
        title: z.string().optional(),
        email: z.string().email({ message: t('Please enter a valid email address') }).optional(),
        slug: z.string().min(1, t('Please enter a valid URL suffix')).regex(/^[a-zA-Z0-9-]+$/, t('URL suffix can only contain letters, numbers, and hyphens')),
        achievements: z.string().optional(),
        skills: z.string().optional(),
        recentWork: z.string().optional(),
    });

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<ResumeFormData>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            language: 'en-US' // 设置默认语言为英文
        }
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isPublishEnabled, setIsPublishEnabled] = useState(false);

    const insertResumeData = async (originalData: ResumeFormData, generatedData: ResumeFormData): Promise<ResumeData> => {
        const response = await fetch('/api/resume', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ originalData, generatedData }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to insert resume');
        }

        const result = await response.json();
        return result.data[0] as ResumeData;
    };

    const onSubmit: SubmitHandler<ResumeFormData> = async (data: ResumeFormData) => {
        setIsSubmitting(true);
        try {
            const supabase = createClientComponentClient();
            const { data: { user } } = await supabase.auth.getUser();

            if (!user) {
                router.push('/signin');
                return;
            }

            const generatedResume = await generateResume(data);
            const insertedResume = await insertResumeData(data, generatedResume as ResumeFormData);

            setResumeData(insertedResume);
            setResumeContent(insertedResume.content_json);

            setIsPublishEnabled(true);
            toast.success(t('Resume generated and saved successfully'));
        } catch (error) {
            // console.error('Error:', error);
            toast.error(t('Failed to generate or save resume. Please try again later'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const publishResume = async () => {
        if (!resumeData.id) {
            toast.error(t('Resume ID is missing.'));
            return;
        }

        try {
            const supabase = createClientComponentClient();
            const { error } = await supabase
                .from('resume')
                .update({ is_publish: 1 })
                .eq('id', resumeData.id);

            if (error) throw error;

            setResumeData({ ...resumeData, is_publish: 1 });
            toast.success(t('Resume published successfully'));
        } catch (error) {
            console.error('Publish Error:', error);
            toast.error(t('Failed to publish resume. Please try again.'));
        }
    };

    return (
        <div className="w-full min-h-screen bg-gradient-to-b from-blue-400 to-purple-500 py-8">
            <div className="max-w-4xl mx-auto px-4 sm:px-6">
                <h1 className="text-3xl font-bold text-white text-center mb-8">{t("AI Social Card Generator")}</h1>
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="w-full md:w-1/2">
                        <form onSubmit={handleSubmit(onSubmit)} className="bg-white rounded-lg shadow-lg p-6 space-y-4">
                            <div className="space-y-2">
                                <label htmlFor="slug" className="block text-sm font-medium text-gray-700">{t("Personal Card URL")}</label>
                                <div className="flex rounded-md">
                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm whitespace-nowrap">
                                        ai-about.me/
                                    </span>
                                    <input
                                        {...register('slug')}
                                        id="slug"
                                        className="w-full px-3 py-2 text-gray-700 border border-l-0 rounded-r-lg focus:outline-none focus:border-indigo-500"
                                        placeholder="your-name"
                                    />
                                </div>
                                {errors.slug && <p className="text-sm text-red-600 mt-1">{errors.slug.message}</p>}
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">{t("Name (Required)")}</label>
                                <input
                                    {...register('name')}
                                    id="name"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Enter your name")}
                                />
                                {errors.name && <p className="text-sm text-red-600 mt-1">{errors.name.message}</p>}
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t("Email")}</label>
                                <input
                                    {...register('email')}
                                    id="email"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Enter your email")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">{t("Location")}</label>
                                <input
                                    {...register('location')}
                                    id="location"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Randomly generate")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="title" className="block text-sm font-medium text-gray-700">{t("Title")}</label>
                                <input
                                    {...register('title')}
                                    id="title"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Randomly generate")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="recentWork" className="block text-sm font-medium text-gray-700">{t("Recent Work")}</label>
                                <textarea
                                    {...register('recentWork')}
                                    id="recentWork"
                                    rows={1}
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 resize-none"
                                    placeholder={t("Randomly generate")}
                                ></textarea>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="achievements" className="block text-sm font-medium text-gray-700">{t("Career Achievements or Highlights")}</label>
                                <textarea
                                    {...register('achievements')}
                                    id="achievements"
                                    rows={1}
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 resize-none"
                                    placeholder={t("Randomly generate")}
                                ></textarea>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="skills" className="block text-sm font-medium text-gray-700">{t("Professional Expertise and Skills")}</label>
                                <textarea
                                    {...register('skills')}
                                    id="skills"
                                    rows={1}
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 resize-none"
                                    placeholder={t("Randomly generate")}
                                ></textarea>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="interests" className="block text-sm font-medium text-gray-700">{t("Personal Interests")}</label>
                                <input
                                    {...register('interests')}
                                    id="interests"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Randomly generate")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="motto" className="block text-sm font-medium text-gray-700">{t("Personal Motto or Attitude")}</label>
                                <input
                                    {...register('motto')}
                                    id="motto"
                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
                                    placeholder={t("Randomly generate")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="language" className="block text-sm font-medium text-gray-700">{t("Language")}</label>
                                <Select onValueChange={(value) => setValue('language', value)} defaultValue={watch('language')}>
                                    <SelectTrigger className="w-full bg-white">
                                        <SelectValue placeholder={t("Select language")} />
                                    </SelectTrigger>
                                    <SelectContent className="bg-white">
                                        {languages.map((lang) => (
                                            <SelectItem key={lang.code} value={lang.code}>
                                                {lang.language}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            <button
                                type="submit"
                                className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 transition-colors duration-200"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? t("Generating...") : t("Generate Social Card")}
                            </button>
                            <button
                                type="button"
                                className={`w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 transition-colors duration-200 mt-2 ${!isPublishEnabled ? 'cursor-not-allowed' : ''}`}
                                onClick={publishResume}
                                disabled={!isPublishEnabled}
                            >
                                {t("Publish")}
                            </button>
                        </form>
                    </div>
                    <div className="w-full md:w-1/2">
                        <PersonalCard
                            {...resumeContent}
                            tags={resumeContent.tags || []}
                            motto={resumeContent.motto || ''}
                            location={resumeContent.location || ''}
                            expertise={resumeContent.expertise || []}
                            interests={resumeContent.interests || []}
                            highlights={resumeContent.highlights || []}
                            recentFocus={resumeContent.recentFocus || ''}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeForm;