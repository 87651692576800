import type { ResumeFormData } from '@/components/ResumeForm';
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";

const supabase = createClientComponentClient();

export const generateResume = async (data: ResumeFormData): Promise<ResumeFormData> => {
  console.log('Generating resume with data:', JSON.stringify(data));

  try {
    const { data: response, error } = await supabase.functions.invoke('generate-resume', {
      body: JSON.stringify(data),
    });

    if (error) {
      throw new Error(`Error calling generate-resume function: ${error.message}`);
    }

    if (!response || !response.success) {
      throw new Error('No resume data returned from the function');
    }

    const generatedResume = JSON.parse(response.comment);
    console.log('Generated resume:', generatedResume);

    // 确保返回的数据符合 ResumeFormData 接口
    return {
      name: generatedResume.name,
      location: generatedResume.location,
      tags: generatedResume.tags,
      recentFocus: generatedResume.recentFocus,
      highlights: generatedResume.highlights,
      expertise: generatedResume.expertise,
      interests: generatedResume.interests,
      motto: generatedResume.motto,
      slug: data.slug, // 使用原始输入的 slug
    };
  } catch (error) {
    console.error('Failed to generate resume:', error);
    throw error;
  }
};


// json报文：{
//   "message": "Hello andy!",
//   "comment": "{\n  \"name\": \"Andy\",\n  \"location\": \"New York, USA\",\n  \"tags\": [\"Technology Enthusiast\", \"Team Player\", \"Creative Thinker\"],\n  \"recentFocus\": \"Exploring new innovations in the tech industry.\",\n  \"highlights\": [\n    \"Led a cross-functional team to develop a product from concept to launch.\",\n    \"Successfully managed a project that improved process efficiency by 30%.\",\n    \"Presented at industry conferences on the future of AI in business.\"\n  ],\n  \"expertise\": [\n    {\n      \"name\": \"Product Development\",\n      \"description\": \"Experience in leading product lifecycle from ideation to release, ensuring alignment with business goals.\"\n    },\n    {\n      \"name\": \"Project Management\",\n      \"description\": \"Proven track record of managing large-scale projects, optimizing workflows and ensuring timely delivery.\"\n    },\n    {\n      \"name\": \"Artificial Intelligence\",\n      \"description\": \"Deep understanding of AI applications and their impact on various industries.\"\n    },\n    {\n      \"name\": \"Team Leadership\",\n      \"description\": \"Effective leadership of cross-functional teams, fostering collaboration and innovation.\"\n    }\n  ],\n  \"interests\": [\"Artificial Intelligence\", \"Digital Innovation\", \"Startups\", \"Sustainability\"],\n  \"motto\": \"Innovate with purpose, lead with integrity.\"\n}",
//   "success": true
// }

// {
//   "message": "Hello andy!",
//   "comment": "{\n  \"name\": \"Andy\",\n  \"location\": \"上海\",\n  \"tags\": [\"全栈开发\", \"技术爱好者\", \"敏捷开发\"],\n  \"recentFocus\": \"专注于构建高效的全栈开发解决方案\",\n  \"highlights\": [\n    \"拥有5年以上的全栈开发经验\",\n    \"参与多个高流量网站的开发与优化\",\n    \"精通前后端技术栈，擅长解决复杂技术问题\"\n  ],\n  \"expertise\": [\n    {\n      \"name\": \"全栈开发\",\n      \"description\": \"熟练掌握前端和后端技术，能够独立完成从设计到部署的全流程开发\"\n    },\n    {\n      \"name\": \"敏捷开发\",\n      \"description\": \"拥有丰富的敏捷开发经验，能够快速迭代产品，响应业务需求\"\n    },\n    {\n      \"name\": \"系统架构\",\n      \"description\": \"具备大型系统的架构设计能力，关注高可用性和可扩展性\"\n    },\n    {\n      \"name\": \"团队协作\",\n      \"description\": \"善于在跨部门团队中合作，推动项目顺利完成\"\n    }\n  ],\n  \"interests\": [\"编程\", \"阅读技术博客\", \"开源项目贡献\", \"旅行\"],\n  \"motto\": \"不断学习，提升自己\"\n}",
//   "success": true
// }