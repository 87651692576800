import pinyin from 'pinyin';

export function stringToColor(str: string): string {
  // 将字符串转换为拼音首字母
  const pinyinStr = pinyin(str, {
    style: pinyin.STYLE_FIRST_LETTER,
    heteronym: false
  }).join('');

  let hash = 0;
  for (let i = 0; i < pinyinStr.length; i++) {
    hash = pinyinStr.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}