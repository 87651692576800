'use client';

import React, { useMemo, useRef, useState } from 'react';
import { Rocket, Trophy, Zap, Heart, Download, Mail, MapPin, Twitter, Facebook } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import { stringToColor } from '@/utils/colorUtils';
import { toPng } from 'html-to-image';
import { ResumeContent } from '@/types/resume';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

const expertiseColors = [
  'bg-blue-100',
  'bg-green-100',
  'bg-purple-100',
  'bg-orange-100',
  'bg-red-100',
  'bg-yellow-100',
  'bg-indigo-100',
  'bg-pink-100',
];

const InfoSection: React.FC<{
  icon: React.ReactElement;
  title: string;
  children: React.ReactNode;
  className?: string;
}> = ({ icon, title, children, className }) => (
  <div className={`mb-6 ${className}`}>
    <div className="flex items-center gap-2 mb-2">
      {icon}
      <h2 className="text-lg font-semibold leading-6">{title}</h2>
    </div>
    {children}
  </div>
);

const PersonalCard: React.FC<ResumeContent> = ({
  name,
  slug,
  email,
  location,
  tags,
  recentFocus,
  highlights,
  expertise,
  interests,
  motto,
}) => {

  console.log("name ===== ", name);
  console.log("slug ===== ", slug);
  console.log("location ===== ", location);
  console.log("tags ===== ", tags);
  console.log("recentFocus ===== ", recentFocus);
  console.log("highlights ===== ", highlights);
  console.log("expertise ===== ", expertise);
  console.log("interests ===== ", interests);
  console.log("motto ===== ", motto);

  const t = useTranslations('PersonalCard');

  const shareUrl = `https://ai-about.me/${slug}`;

  const cardRef = useRef<HTMLDivElement>(null);
  const avatarColor = useMemo(() => stringToColor(name), [name]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const handleDownload = async () => {
    if (cardRef.current) {
      setIsGeneratingImage(true);
      try {
        const dataUrl = await toPng(cardRef.current, {
          pixelRatio: 2,
          style: {
            fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
          },
        });

        const link = document.createElement('a');
        link.download = `${name.replace(/\s+/g, '_')}_personal_card.png`;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Error generating image:', error);
      } finally {
        setIsGeneratingImage(false);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-50 p-4 sm:p-6 font-sans">
      <div
        id="personal-card"
        ref={cardRef}
        className={`bg-white rounded-2xl max-w-md w-full overflow-hidden shadow-lg transition-shadow duration-300 ${isGeneratingImage ? '' : 'hover:shadow-2xl'
          }`}
        style={{ width: '100%', maxWidth: '28rem' }}
      >
        {/* 头部信息 */}
        <div className="bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 p-6 text-white">
          <div className="flex flex-col sm:flex-row items-center">
            <div
              className="w-24 h-24 rounded-full border-4 border-white flex items-center justify-center text-3xl font-bold shadow-lg"
              style={{ backgroundColor: avatarColor }}
            >
              {name.charAt(0).toUpperCase()}
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-6 flex-1 text-center sm:text-left">
              <h1 className="text-2xl font-bold mb-2">{name}</h1>
              <div className="space-y-1">
                <p className="text-sm flex items-center justify-center sm:justify-start">
                  <MapPin className="w-4 h-4 mr-2" aria-hidden="true" />
                  <span>{location}</span>
                </p>
                {email && email.trim() && (
                  <p className="text-sm flex items-center justify-center sm:justify-start">
                    <Mail className="w-4 h-4 mr-2" aria-hidden="true" />
                    <span>{email}</span>
                  </p>
                )}
              </div>
              <div className="flex flex-wrap gap-2 mt-3 justify-center sm:justify-start">
                {tags.map((tag: string, index: number) => (
                  <span
                    key={index}
                    className="bg-white bg-opacity-20 text-white px-3 py-1 rounded-full text-xs font-medium backdrop-blur-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* 主体内容 */}
        <div className="p-6">
          {/* 近期关键投入 */}
          <InfoSection
            icon={<Rocket className="w-6 h-6 text-indigo-500" />}
            // title="近期关键投入"
            title={t('Recent Key Focus')}
            className="bg-indigo-100 text-indigo-800 p-4 rounded-lg"
          >
            <div className="flex items-center">
              <span className="text-sm">{recentFocus}</span>
            </div>
          </InfoSection>

          {/* 履历亮点 */}
          <InfoSection icon={<Trophy className="w-6 h-6 text-yellow-500" />} 
          // title="履历亮点"
          title={t('Career Highlights')}
          >
            <ul className="list-disc list-inside text-sm text-gray-700 space-y-1">
              {highlights.map((highlight, index) => (
                <li key={index}>{highlight}</li>
              ))}
            </ul>
          </InfoSection>

          {/* 擅长领域 */}
          <InfoSection icon={<Zap className="w-6 h-6 text-green-500" />} 
          // title="擅长领域"
          title={t('Areas of Expertise')}
          >
            <div className="grid grid-cols-2 gap-2">
              {expertise.map((item, index) => (
                <div
                  key={index}
                  className={`${expertiseColors[index % expertiseColors.length]} p-3 rounded-lg shadow-sm`}
                >
                  <h3 className="font-medium text-gray-800">{item.name}</h3>
                  <p className="text-xs text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </InfoSection>

          {/* 兴趣爱好 */}
          <InfoSection icon={<Heart className="w-6 h-6 text-red-500" />} 
          // title="兴趣爱好"
          title={t('Interests')}
          >
            <p className="text-sm text-gray-700">{interests.join(', ')}</p>
          </InfoSection>
        </div>

        {/* 页脚 */}
        <div className="bg-gray-100 p-4 flex justify-between items-center">
          <p className="text-sm text-gray-600 italic">&quot;{motto}&quot;</p>
          <QRCodeSVG
            value={`https://ai-about.me/${slug}`}
            size={48}
            bgColor="#ffffff"
            fgColor="#4B5563"
            level="L"
            includeMargin={false}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col items-center">
        <button
          onClick={handleDownload}
          className="w-full bg-indigo-500 hover:bg-indigo-600 text-white px-6 py-2 rounded-full flex items-center justify-center text-sm font-medium shadow-md transition-colors duration-300"
          disabled={isGeneratingImage}
        >
          {isGeneratingImage ? (
            <span>Generating...</span>
          ) : (
            <>
              <Download className="w-5 h-5 mr-2" />
              Download Card
            </>
          )}
        </button>

        <div className="mt-3 flex items-center">
          <span className="text-sm text-gray-500 mr-2">Share:</span>
          <div className="flex space-x-2">
            <Link
              href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 transition-colors duration-300"
              aria-label="Share to Twitter"
            >
              <Twitter className="h-5 w-5 text-gray-600" />
            </Link>
            <Link
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 rounded-full bg-gray-100 hover:bg-blue-100 transition-colors duration-300"
              aria-label="Share to Facebook"
            >
              <Facebook className="h-5 w-5 text-gray-600" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalCard;